<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>服务分类编辑</div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">
                    <el-form-item label="分类名" prop="name" :rules="[{required:true,message:'分类名不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.name"></el-input></el-form-item>
                    <el-form-item label="父分类" prop="pid">
                        <el-select v-model="info.pid" placeholder="请选择分类" @change="selectChange()">
                            <el-option label="顶级分类" :value="0"></el-option>
                            <el-option v-for="(v,k) in list" :label="v.name" :key="k" :value="v.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <!--<el-form-item label="缩略图" prop="thumb"><el-upload class="avatar-uploader" :action="$api.goodsClassUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccess" >
                        <img v-if="info.thumb" :src="info.thumb" class="avatar-upload">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload></el-form-item>-->


                    <el-form-item label="服务主图" prop="thumb" class="width_auto">
                        <el-upload :action="$api.goodsUpload" :headers="upload_headers" list-type="picture-card" ref="upload" :file-list="file_list2" :limit="5" :multiple="true"  :on-success="handleAvatarSuccess" :on-exceed="onExceed" >
                            <!-- <i  class="el-icon-plus avatar-uploader-icon"></i> -->
                            <i slot="default" class="el-icon-plus"></i>
                            <div slot="file" slot-scope="{file}">
                                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" >
                                <div class="is_master" :style="get_master_image(file)"><i class="el-icon-finished"> 主图</i></div>
                                <span class="el-upload-list__item-actions">
                                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="setMaster(file)" >
                                        <i class="el-icon-finished"></i>
                                    </span>
                                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handlePictureCardPreview(file)" >
                                        <i class="el-icon-zoom-in"></i>
                                    </span>
                                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)" >
                                        <i class="el-icon-delete"></i>
                                    </span>
                                </span>
                            </div>
                        </el-upload>
                    </el-form-item>



                    <el-form-item label="分佣(百分比)" prop="rate" :rules="[{required:true,message:'分佣不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.rate"><span slot="append">%</span></el-input></el-form-item>
                    <el-form-item label="标签" prop="tags"><el-input placeholder="如：手机,电脑" v-model="info.tags"></el-input></el-form-item>
                    <el-form-item label="排序" prop="is_sort"><el-input placeholder="请输入内容" type="number"  v-model="info.is_sort"></el-input></el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <el-button @click="resetForm('info')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          edit_id:0,
          info:{
              rate:0,
              is_sort:0,
              goods_master_image:'',
          },
          list:[],
          upload_headers:{},
          file_list:[],
          file_list2:[], // element 内置file_list
      };
    },
    watch: {},
    computed: {},
    methods: {
        resetForm:function(e){
            this.$refs[e].resetFields();
        },
        submitForm:function(e){
            let _this = this;

            // 验证表单
            this.$refs[e].validate(function(res){
                if(res){
                    // 图片 验证
                    if(_this.file_list.length<=0){
                        return _this.$message.error('商品主图至少上传一张');
                    }

                    _this.info.file_list = _this.file_list; // 图片
                    //  判断是Add 或者 Edit
                    let url = _this.$api.addFuwClass;
                    if(_this.edit_id>0){
                        url = _this.$api.editFuwClass+_this.edit_id;
                    }
                    // Http 请求
                    _this.$post(url,_this.info).then(function(res){
                        if(res.code == 200){
                            _this.$message.success("编辑成功");
                            _this.$router.go(-1);
                        }else{
                            _this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },

        selectChange:function(){
            this.$forceUpdate();
        },
        handleAvatarSuccess(res) {
            if(this.file_list.length==0){
                this.info.goods_master_image = res.data;
            }
            this.file_list.push(res.data);
            this.$forceUpdate();
        },
        handlePictureCardPreview:function(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 文件删除
        handleRemove:function(file){
            // console.log(file,this.$refs.upload);
            this.$refs.upload.handleRemove(file);
            let index = this.file_list.indexOf(file.url);
            this.file_list.splice(index,1);
        },
        // 设置主图
        setMaster:function(file){
            this.info.goods_master_image = file.response.data;
        },
        // 上传超过限制
        onExceed:function(){
            this.$message.error('商品展示图片不能超过5个');
        },
        // 打开选择规格属性
        open_spec_table:function(){
            this.dialogTableVisible = !this.dialogTableVisible;
        },
        chose_spec:function(res){
            this.dialogTableVisible = !this.dialogTableVisible;
            this.chose_spec_list = res;
        },
        // 选择运费模版
        chose_freight_template:function(res){
            this.chose_freight_template_show = !this.chose_freight_template_show;
            this.info.freight_id = res[0].id;
            this.freight_info = res[0].name;
        },
        get_master_image:function(file){
            if(file.url != undefined){
                // console.log(file.response.data,this.info.goods_master_image)
                if(file.url == this.info.goods_master_image){
                    return 'display:block';
                }else{
                    return 'display:none';
                }
            }
        },
        get_goods_class_info:function(){
            let _this = this;
            this.$get(this.$api.editFuwClass+this.edit_id).then(function(res){
                _this.info = res.data.info;
                _this.list = res.data.list;
                _this.file_list = res.data.info.goods_images.split(',');
                res.data.info.goods_images.split(',').forEach(imgRes=>{
                    _this.file_list2.push({name:'',url:imgRes});
                });
            })

        },
        get_goods_class_list:function(){
            let _this = this;
            this.$get(this.$api.addFuwClass).then(function(res){
                _this.list = res.data;
            })
        },
    },
    created() {
        this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到

        // 判断是否是编辑
        this.info.pid = 0;
        if(!this.$isEmpty(this.$route.params.id)){
            this.edit_id = this.$route.params.id;
        }else{
            return this.get_goods_class_list();
        }

        if(this.edit_id>0){
            this.get_goods_class_info();
        }
        
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>